<script lang="ts" setup>
import type { MenuItem } from './menu-item';

import { useNuxtApp } from '#app';

const props = defineProps<{
  companyName: string;
  logoUrl?: string;
  menu: MenuItem[];
  authenticated: boolean;
  guestName?: string;
}>();

const emits = defineEmits<{
  (e: 'click:drawer'): void;
  (e: 'goHome'): void;
}>();

const { $pwa, $device: { isMobile, isApple } } = useNuxtApp();

const displayPWAInstaller = computed<boolean>(() => {
  return isMobile && !isApple && !!$pwa && !$pwa.isPWAInstalled;
});

const innerMenu = computed<MenuItem[]>(() =>
  props.authenticated ? props.menu : [...props.menu].reverse()
);

const onDrawer = (): void => {
  emits('click:drawer');
};

const onGoHome = (): void => {
  emits('goHome');
};

const onPWAInstall = async (): Promise<void> => {
  await $pwa?.install();
};
</script>

<template>
  <div class="header bg-header h-header shadow-lg">
    <UContainer class="header__container">
      <UButton
        v-if="isMobile"
        class="rounded-full text-inherit hover:bg-header"
        variant="ghost"
        data-test="drawer"
        aria-label="drawer"
        @click="onDrawer"
      >
        <template #leading>
          <UIcon
            name="ic:round-menu"
            class="text-2xl"
          />
        </template>
      </UButton>
      <div
        class="header__title"
        :class="isMobile ? 'text-center' : undefined"
        data-test="title"
      >
        <div
          v-if="props.logoUrl"
          class="header__logo"
        >
          <img
            :src="props.logoUrl"
            alt="logo"
            title="logo"
            data-test="company-logo"
            @click="onGoHome"
          >
        </div>
        <div
          v-else
          class="header__company-name font-bernier text-5xl cursor-pointer"
          data-test="company-name"
          @click="onGoHome"
        >
          {{ props.companyName }}
        </div>
      </div>

      <div style="width: 35.6px">
        <UButton
          v-if="displayPWAInstaller"
          class="rounded-full text-inherit hover:bg-header"
          variant="ghost"
          data-test="pwa"
          aria-label="pwa"
          @click="onPWAInstall"
        >
          <template #leading>
            <UIcon
              name="ic:round-install-mobile"
              class="text-2xl"
            />
          </template>
        </UButton>
      </div>
      <ul
        v-if="!isMobile"
        class="header__menu text-regular"
      >
        <UButton
          v-if="guestName"
          class="text-inherit"
          variant="solid"
          size="lg"
          data-test="guest-buttom"
          :label="`Logged as Guest: ${guestName}`"
        />
        <template
          v-for="(item, index) in innerMenu"
          :key="index"
        >
          <li
            v-if="item.visible !== false"
            :class="{ 'header__menu--separator': item.separator }"
            data-test="list-item"
          >
            <UButton
              class="uppercase text-inherit"
              :class="item.class ?? 'hover:bg-header'"
              :label="item.label"
              :variant="item.class ? 'solid' : 'ghost'"
              size="lg"
              :to="item"
              data-test="menu-item"
            >
              <template #leading>
                <UIcon
                  v-if="item.alwaysShowIcon"
                  :name="item.icon"
                  class="text-2xl"
                  data-test="icon"
                  dynamic
                />
              </template>
            </UButton>
          </li>
        </template>
      </ul>
    </UContainer>
  </div>
</template>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  &__container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    &>.header__title {
      display: flex;
      align-items: center;
      height: 100%;

      &>.header__logo {
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-inline: 0.5rem;
        padding-block: 0.5rem;

        &>img {
          max-width: 100%;
          max-height: 100%;
          cursor: pointer;
        }
      }

      &>.header__company-name {
        text-shadow: 2px 3px 2px var(--header-text-shadow-color);
      }
    }
  }

  &__menu {
    display: flex;
    margin: 0;
    padding-block: 0.5rem;
    box-sizing: border-box;
    gap: 1rem;
    height: 100%;
    align-items: center;

    &--separator {
      display: flex;

      &::after {
        content: "";
        border-right: 1px solid white;
        margin-left: 10px;
      }
    }
  }
}
</style>
